/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&family=Noto+Sans+Arabic&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400&family=Prosto+One&display=swap');

:root {
    --background-color-1: linear-gradient(145deg, #1e2024, #23272b);
    --background-color-2: #212428;
    --shadow-1: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    --shadow-2: inset 21px 21px 19px #181a1d, inset -21px -21px 19px #202225;
}

html {
    scroll-behavior: smooth;
}

body {

    background-color: #111315;
    color: whitesmoke;
    /* font-family: 'Inter', sans-serif; */

    font-family: 'Prosto One', cursive;
    font-family: 'Lato', sans-serif;


}

.box-grad {

    border-radius: 24px;
    background: rgb(15, 15, 15);
    background-image: linear-gradient(to right, #16181c,
            #141414, #141414);
    /* background-image: var(--background-color-1); */
    background: linear-gradient(to right bottom, #15171a, #181b1f);
    box-shadow: 0px 0px 8px #1c1e22, -0px -0px 8px #262a2e;
    border: 1px solid #161616;
    position: relative;
    transition: 300ms linear;
    cursor: pointer;

}


.bg-box {
    background: linear-gradient(to right bottom, #15171a, #181b1f);
    box-shadow: 0px 0px 8px #1c1e22, -0px -0px 8px #262a2e;
}

.box-grad .icon {
    position: absolute;
    right: 16px;
    bottom: 16px;
    opacity: .4;
    transition: 300ms linear;
}

.box-grad:hover .icon {
    opacity: 1;
}

.box-grad:hover {
    transform: scale(1.01);
}

.avatar {
    border-radius: 30px 4px;
    position: relative;
}



.bg-grad {
    background: rgb(15, 15, 15);
    background-image: linear-gradient(to right, #0b0b0b,
            #121212, #161515);
    border: 1px solid #202020;
}

.bg-avatar {
    background-image: linear-gradient(to right, #5a78ea, #5a8ff2, #64a5f8, #79b9fb, #93ccfc, #99d0fa, #9fd4f9, #a6d8f7, #95cff2, #83c6ed, #70bee8, #5ab5e3);
}

.social-link {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: linear-gradient(120deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.01));
    transition: .3s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-link:hover,
.theme-btn:hover {
    background: #ffffff;
    color: #0F0F0F;
}

.theme-btn {
    background: #323332;
    color: #fff;
    display: inline-block;
    transition: .3s;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 500;
    padding: 12px 30px;
    text-align: center;
}

.sticky-top {
    position: sticky;
    top: 3%;
}


.swiper-button-prev:after, .swiper-button-next:after {
    font-size: 1.2rem !important;
    background-color: #212428;
    padding: .7rem 1.1rem;
    border-radius: 50%;
    font-weight: 700;
    color: white;
    box-shadow: 0px 0px 4px #1c1e22, -0px -0px 4px #262a2e;
}

.swiper-model{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.05);
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 400ms linear;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-200%);
    transform-origin: top left;
}

.swiper-model.show{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}


.skillsIcon i{
    font-size: 50px !important;
}

@media (max-width:576px){
    .skillsIcon i{
        font-size: 36px !important;
    }
}

