@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components{
    .text-large{
        @apply text-2xl md:text-3xl;
    }

    .text-medium{
        @apply text-xl md:text-2xl;
    }

    .text-small{
        @apply text-base md:text-lg text-justify leading-8 text-gray-300;
    }

    .flex-col-start{
        @apply flex flex-col items-start;
    }
}
